import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Booking Detail Component
const BookingDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/booking/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل الحجز</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم الحجز</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف المستخدم</label>
                            <input type="text" value={item.userId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف الخدمة</label>
                            <input type="text" value={item.serviceId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الحالة</label>
                            <input type="text" value={item.status} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تكوين القائمة</label>
                            <input type="text" value={item.menu_config} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ الإنشاء</label>
                            <input type="text" value={item.createdAt} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">طريقة الدفع</label>
                            <input type="text" value={item.paymentMethod} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">التكلفة الإجمالية</label>
                            <input type="text" value={item.totalCost} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الإعدادات</label>
                            <input type="text" value={item.sets} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">ملاحظات</label>
                            <input type="text" value={item.notes} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ البدء</label>
                            <input type="text" value={item.startDate} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ الانتهاء</label>
                            <input type="text" value={item.endDate} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الكود</label>
                            <input type="text" value={item.code} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المستخدم</label>
                            <input type="text" value={item.user} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الخدمة</label>
                            <input type="text" value={item.service} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المزود</label>
                            <input type="text" value={item.provider} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingDetail;