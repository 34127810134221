import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl } from '../../constants';

// Order Edit Component
const OrderEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({ id: '', price: '', status: '', user_id: '', org_id: '', date: '', delivery_time: '', user_lat: '', user_lng: '', provider_lat: '', provider_lng: '', address: '', delivery_fee: '', payment: '', descAddress: '', code: '', user: '', carts: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/order/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl + `api/order/` + item.id, item);
        // Redirect or show success message
        navigate(`/admin/order`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل الطلب</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">معرف الطلب</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">السعر</label>
                        <input type="text" name="price" value={item.price} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف المستخدم</label>
                        <input type="text" name="user_id" value={item.user_id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف المؤسسة</label>
                        <input type="text" name="org_id" value={item.org_id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ</label>
                        <input type="text" name="date" value={item.date} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">وقت التسليم</label>
                        <input type="text" name="delivery_time" value={item.delivery_time} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط العرض</label>
                        <input type="text" name="user_lat" value={item.user_lat} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط الطول</label>
                        <input type="text" name="user_lng" value={item.user_lng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط عرض المزود</label>
                        <input type="text" name="provider_lat" value={item.provider_lat} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط طول المزود</label>
                        <input type="text" name="provider_lng" value={item.provider_lng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان</label>
                        <input type="text" name="address" value={item.address} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">رسوم التوصيل</label>
                        <input type="text" name="delivery_fee" value={item.delivery_fee} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">طريقة الدفع</label>
                        <input type="text" name="payment" value={item.payment} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">وصف العنوان</label>
                        <input type="text" name="descAddress" value={item.descAddress} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الرمز</label>
                        <input type="text" name="code" value={item.code} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المستخدم</label>
                        <input type="text" name="user" value={item.user} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العربات</label>
                        <input type="text" name="carts" value={item.carts} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default OrderEdit;