import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

const TripEdit = () => {
    const { id } = useParams();
    const [item, setItem] = useState({ id: '', description: '', lat: '', lng: '', notes: '', user: '', status: '', orgId: '', date: '', offerId: '', userId: '', chips: '', persons: '', price: '', address: '', startDate: '', endDate: '', menu: '', description_en: '', payment: '', code: '', photos: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/trip/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl + `api/trip/` + id, item);
        // Redirect or show success message
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل الرحلة</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">المعرف</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف</label>
                        <input type="text" name="description" value={item.description} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط العرض</label>
                        <input type="text" name="lat" value={item.lat} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">خط الطول</label>
                        <input type="text" name="lng" value={item.lng} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">ملاحظات</label>
                        <input type="text" name="notes" value={item.notes} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المستخدم</label>
                        <input type="text" name="user" value={item.user} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف المنظمة</label>
                        <input type="text" name="orgId" value={item.orgId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">التاريخ</label>
                        <input type="text" name="date" value={item.date} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف العرض</label>
                        <input type="text" name="offerId" value={item.offerId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف المستخدم</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">رقائق</label>
                        <input type="text" name="chips" value={item.chips} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الأشخاص</label>
                        <input type="text" name="persons" value={item.persons} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">السعر</label>
                        <input type="text" name="price" value={item.price} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان</label>
                        <input type="text" name="address" value={item.address} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ البدء</label>
                        <input type="text" name="startDate" value={item.startDate} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ الانتهاء</label>
                        <input type="text" name="endDate" value={item.endDate} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">القائمة</label>
                        <input type="text" name="menu" value={item.menu} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الوصف (بالإنجليزية)</label>
                        <input type="text" name="description_en" value={item.description_en} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الدفع</label>
                        <input type="text" name="payment" value={item.payment} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الكود</label>
                        <input type="text" name="code" value={item.code} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الصور</label>
                        <input type="text" name="photos" value={item.photos} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default TripEdit;