import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl } from '../../constants';

// مكون تعديل المستخدم
const UserEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({ 
        aboutText: '', 
        banarImage: '', 
        city: '', 
        country: '', 
        deviceToken: '', 
        fullName: '', 
        phone: '', 
        profileImage: '', 
        password: '', 
        role: '', 
        statuse: '', 
        identityNumber: '', 
        rate: '', 
        bank: '', 
        lang: '', 
        ibanNumber: '', 
        code: '', 
        terms: '', 
        lat: '', 
        lng: '', 
        descAddress: '', 
        lnk: '', 
        balance: '', 
        menu_config: '', 
        imageBank: '', 
        createdAt: '', 
        id: '', 
        userName: '', 
        normalizedUserName: '', 
        email: '', 
        normalizedEmail: '', 
        emailConfirmed: '', 
        passwordHash: '', 
        securityStamp: '', 
        concurrencyStamp: '', 
        phoneNumber: '', 
        phoneNumberConfirmed: '', 
        twoFactorEnabled: '', 
        lockoutEnd: '', 
        lockoutEnabled: '', 
        accessFailedCount: '' 
    });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/user/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl + `api/user/` + item.id, item);
        // إعادة التوجيه أو عرض رسالة النجاح
        navigate(`/admin/user`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل المستخدم</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">نبذه عن المستخدم</label>
                        <input type="text" name="aboutText" value={item.aboutText} onChange={handleChanges} className="form-control" />
                    </div>
  
                    <div className="col-6">
                        <label className="form-label">الاسم الكامل</label>
                        <input type="text" name="fullName" value={item.fullName} onChange={handleChanges} className="form-control" />
                    </div>
                
                  
                
                    <div className="col-6">
                        <label className="form-label">تاريخ التسجيل</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
               
                
                    <div className="col-6">
                        <label className="form-label">البريد الإلكتروني</label>
                        <input type="text" name="email" value={item.email} onChange={handleChanges} className="form-control" />
                    </div>
               
                    <div className="col-6">
                        <label className="form-label">رقم الهاتف</label>
                        <input type="text" name="userName" value={item.userName} onChange={handleChanges} className="form-control" />
                    </div>
                
               
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UserEdit;