
import { useLocation } from 'react-router-dom';
import './style.css'
import { baseurl } from '../../constants';
import Services from './Services';
export default function ProviderDetail() {
  const location = useLocation();
  const row = location.state.row;
  return <>
    <div className="">
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">مزودي الخدمة</div>
        <div className="ps-3">
          <nav aria-label="a">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">تفاصيل</li>
            </ol>
          </nav>
        </div>
      </div>
      <hr />
      <div className="col-xl-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">معلومات المزود</h6>
              <hr />
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
                <div className="col-6">
                  <div className="col-12">
                    <label className="form-label">الاسم </label>
                    <input disabled autoComplete="off" value={row.fullName} type="text" className="form-control" />
                    <span asp-validation-for="Name" className="text-danger" />
                  </div>
                  <div className="col-12">
                    <label className="form-label">رقم الهاتف </label>
                    <input disabled autoComplete="off" value={row.userName} type="text" className="form-control" />
                    <span asp-validation-for="Name" className="text-danger" />
                  </div>
                  <div className="col-12 my-3">
                    <label className="form-label">المدينة </label>
                    <input disabled autoComplete="off" value={row.city} type="text" className="form-control" />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>
                  <div className="col-12 my-3">
                    <label className="form-label">نبذة عن </label>
                    <input disabled autoComplete="off" value={row.aboutText} type="text" className="form-control" />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>

                  <div className="col-12 my-3">
                    <label className="form-label">تاريخ الانضمام </label>
                    <input disabled autoComplete="off" value={row.createdAt} type="text" className="form-control" />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div> 
                </div>

                <div className='col-6'>
                <div className="col-12 my-3">
                    <label className="form-label">البنك </label>
                    <input disabled autoComplete="off" value={row.bank} type="text" className="form-control" />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>
                  <div className="col-12 my-3">
                    <label className="form-label">الايبان </label>
                    <input disabled autoComplete="off" value={row.ibanNumber} type="text" className="form-control" />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>
                  <div className="col-12 my-3">
                    <label className="form-label">الايميل </label>
                    <input disabled autoComplete="off" value={row.email} type="text" className="form-control" />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>
                  <div className="col-12 my-3">
                    <label className="form-label">الرصيد </label>
                    <input disabled autoComplete="off" value={row.balance} type="text" className="form-control" />
                    <span asp-validation-for="NameEn" className="text-danger" />
                  </div>

                  <div className="col-6 text-center">
                  <label htmlFor="ImageFile" className="form-label">صورة </label>
                  <div className="text-center">
                    <img style={{ width: '10vw', height: 'auto' }} src={baseurl + "uploads/" + row.profileImage} className="rounded float-center" />
                  </div>
                </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Services id={row.id} />
  </>
}