import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';


const TripDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/trip/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل الرحلة</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم التعريف (ID)</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">وصف</label>
                            <input type="text" value={item.description} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">خط العرض (Lat)</label>
                            <input type="text" value={item.lat} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">خط الطول (Lng)</label>
                            <input type="text" value={item.lng} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">ملاحظات</label>
                            <input type="text" value={item.notes} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المستخدم</label>
                            <input type="text" value={item.user} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">حالة</label>
                            <input type="text" value={item.status} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم المنظمة (Org ID)</label>
                            <input type="text" value={item.orgId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ</label>
                            <input type="text" value={item.date} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم العرض (Offer ID)</label>
                            <input type="text" value={item.offerId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم المستخدم (User ID)</label>
                            <input type="text" value={item.userId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">رقائق (Chips)</label>
                            <input type="text" value={item.chips} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">عدد الأشخاص (Persons)</label>
                            <input type="text" value={item.persons} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">السعر</label>
                            <input type="text" value={item.price} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">عنوان</label>
                            <input type="text" value={item.address} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ البدء</label>
                            <input type="text" value={item.startDate} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ الانتهاء</label>
                            <input type="text" value={item.endDate} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">قائمة</label>
                            <input type="text" value={item.menu} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">وصف بالإنجليزية</label>
                            <input type="text" value={item.description_en} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الدفع</label>
                            <input type="text" value={item.payment} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">رمز</label>
                            <input type="text" value={item.code} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">صور</label>
                            <input type="text" value={item.photos} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TripDetail;