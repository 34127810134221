import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';
import BundelImages from './ServiceImages';

// Service Edit Component
const ServiceEdit = () => {
    const { id } = useParams();
    const [item, setItem] = useState({ id: '', country: '', name: '', image: '', duration: '', desc: '', eventDate: '', cost: '', statuse: '', location: '', late: '', lng: '', categoryId: '', userId: '', bundleId: '', createdAt: '', maxBookings: '', payments: '', minPersons: '', countBookings: '', totalPersons: '', city: '', price: '', address: '', descAddress: '', lnk: '', meta: '', startTime: '', isBooked: '', desc_en: '', name_en: '' });
    const [images, setImages] = useState();

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/service/` + id);
            setItem(response.data.item);
            setImages(response.data.images);

        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/service/${id}`, item);
        // Redirect or show success message
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل الخدمة</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
               
                   
                    <div className="col-6">
                        <label className="form-label">الاسم</label>
                        <input type="text" name="name" value={item.name} onChange={handleChanges} className="form-control" />
                    </div>
               
                  
                    <div className="col-6">
                        <label className="form-label">الوصف</label>
                        <input type="text" name="desc" value={item.desc} onChange={handleChanges} className="form-control" />
                    </div>
                   
                    <div className="col-6">
                        <label className="form-label">التكلفة</label>
                        <input type="text" name="cost" value={item.cost} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <input type="text" name="statuse" value={item.statuse} onChange={handleChanges} className="form-control" />
                    </div>
               
           
                    <div className="col-6">
                        <label className="form-label">تاريخ الإنشاء</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">أقصى عدد للأشخاص</label>
                        <input type="text" name="maxBookings" value={item.maxBookings} onChange={handleChanges} className="form-control" />
                    </div>
                
                  
                    <div className="col-6">
                        <label className="form-label">المدينة</label>
                        <input type="text" name="city" value={item.city} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">السعر</label>
                        <input type="text" name="price" value={item.price} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">العنوان</label>
                        <input type="text" name="address" value={item.address} onChange={handleChanges} className="form-control" />
                    </div>
                
                 
            
                </div>
            </form>

        </div>
    );
};

export default ServiceEdit;