import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Booking Edit Component
const BookingEdit = () => {
    const { id } = useParams();
    const [item, setItem] = useState({ id: '', userId: '', serviceId: '', status: '', menu_config: '', createdAt: '', paymentMethod: '', totalCost: '', sets: '', notes: '', startDate: '', endDate: '', code: '', user: '', service: '', provider: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/booking/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl+`api/booking/${id}`, item);
        // Redirect or show success message
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل الحجز</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">رقم الحجز</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف المستخدم</label>
                        <input type="text" name="userId" value={item.userId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">معرف الخدمة</label>
                        <input type="text" name="serviceId" value={item.serviceId} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تكوين القائمة</label>
                        <input type="text" name="menu_config" value={item.menu_config} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ الإنشاء</label>
                        <input type="text" name="createdAt" value={item.createdAt} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">طريقة الدفع</label>
                        <input type="text" name="paymentMethod" value={item.paymentMethod} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">التكلفة الإجمالية</label>
                        <input type="text" name="totalCost" value={item.totalCost} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الإعدادات</label>
                        <input type="text" name="sets" value={item.sets} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">ملاحظات</label>
                        <input type="text" name="notes" value={item.notes} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ البدء</label>
                        <input type="text" name="startDate" value={item.startDate} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">تاريخ الانتهاء</label>
                        <input type="text" name="endDate" value={item.endDate} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الكود</label>
                        <input type="text" name="code" value={item.code} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المستخدم</label>
                        <input type="text" name="user" value={item.user} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الخدمة</label>
                        <input type="text" name="service" value={item.service} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">المزود</label>
                        <input type="text" name="provider" value={item.provider} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BookingEdit;