import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// مكون تفاصيل المستخدم
const UserDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/user/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل المستخدم</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">نبذه</label>
                            <input type="text" value={item.aboutText} disabled className="form-control" />
                        </div>
                       
                            <div className="col-md-6 mb-3">
                            <label className="form-label">صورة الملف الشخصي</label>
                            <img src={baseurl + "/uploads/" + item.banarImage} className="rounded-circle" width={44} height={44} alt="" />
                            </div>


                        <div className="col-md-6 mb-3">
                            <label className="form-label">المدينة</label>
                            <input type="text" value={item.city} disabled className="form-control" />
                        </div>
                    
                    
                        <div className="col-md-6 mb-3">
                            <label className="form-label">الاسم الكامل</label>
                            <input type="text" value={item.fullName} disabled className="form-control" />
                        </div>
                    
                        <div className="col-md-6 mb-3 ">
                            <label className="form-label mx-3">صورة الملف الشخصي</label>
                            <img src={baseurl + "/uploads/" + item.profileImage} className="mx-3   rounded-circle" width={44} height={44} alt="" />
                            </div>

                        
                       
                        <div className="col-md-6 mb-3">
                            <label className="form-label">تاريخ الإنشاء</label>
                            <input type="text" value={item.createdAt} disabled className="form-control" />
                        </div>
                        
                        <div className="col-md-6 mb-3">
                            <label className="form-label">البريد الإلكتروني</label>
                            <input type="text" value={item.email} disabled className="form-control" />
                        </div>
                       
                        <div className="col-md-6 mb-3">
                            <label className="form-label">رقم الهاتف</label>
                            <input type="text" value={item.userName} disabled className="form-control" />
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UserDetail;