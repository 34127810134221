
import loadjs from 'loadjs';
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import ExternalScripts from './ExternalScripts';


export default function Sidebar (){

  const navigateTo = useNavigate()

  return <aside className="sidebar-wrapper" data-simplebar="true">

  <ExternalScripts/>

 <div className="sidebar-header">
   <div>
     <img src="../images/logo.png" className="logo-icon" alt="logo icon" />
   </div>
   <div>
     <h4 className="logo-text">طلعات </h4>
   </div>
   <div className="toggle-icon ms-auto"> <i className="bi bi-list" />
   </div>
 </div>
 {/*navigation*/}
 <ul className="metismenu" id="menu">
   <li>
     <a onClick={e=>navigateTo("/admin/home")} aria-expanded="true">
       <div className="parent-icon"><i className="bi bi-house-fill" />
       </div>
       <div className="menu-title">الرئيسية</div>
     </a>
   </li>
   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bi bi-people-fill" />
       </div>
       <div className="menu-title">المستخدمين</div>
     </a>
     <ul>
       <li onClick={e=>navigateTo("/admin/user")}> <a ><i className="bi bi-circle" />قائمة المستخدمين</a>
       </li>
     </ul>
   </li>

   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bi bi-people-fill" />
       </div>
       <div className="menu-title">مزودي الخدمة</div>
     </a>
     <ul>
       <li onClick={e=>navigateTo("/admin/providers")}> <a ><i className="bi bi-circle" />قائمة المزودين</a>
       </li>
     </ul>
   </li>


   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bi bi-grid-fill" />
       </div>
       <div className="menu-title">الفئات</div>
     </a>
     <ul>
       

       <li> <a onClick={e=>navigateTo("/admin/category")}><i className="bi bi-circle" />قائمة الفئات</a>
       </li>

     </ul>
   </li>
   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bi bi-basket2-fill" />
       </div>
       <div className="menu-title">الطلعات</div>
     </a>
     <ul>
       <li onClick={e=>navigateTo("/admin/service")}> <a ><i className="bi bi-circle" />قائمة الطلعات</a>
       </li>

       <li onClick={e=>navigateTo("/admin/booking")}> <a ><i className="bi bi-circle" />قائمة الحجوزات</a>
       </li>

     </ul>
   </li>
   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bi bi-award-fill" />
       </div>
       <div className="menu-title">الطلعات الخاصة</div>
     </a>
     <ul>
       <li onClick={e=>navigateTo("/admin/trip")}> <a ><i className="bi bi-circle" />قائمة الطلعات</a>
       </li>
  
     </ul>
   </li>

   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bi bi-award-fill" />
       </div>
       <div className="menu-title">المعاملات المالية</div>
     </a>
     <ul>
       <li onClick={e=>navigateTo("/admin/transactions")}> <a ><i className="bi bi-circle" />المعاملات المالية</a>
       </li>
  
     </ul>
   </li>



   <li>
     <a href="javascript:;" className="has-arrow">
       <div className="parent-icon"><i className="bx bxs-report" />
       </div>
       <div className="menu-title">المنيو</div>
     </a>
     <ul>
       <li onClick={e=>navigateTo("/admin/menu")}> <a ><i className="bi bi-circle" />قائمة المنيو</a>
       </li>

       <li onClick={e=>navigateTo("/admin/menucategory")}> <a ><i className="bi bi-circle" />الأقسام</a>
       </li>
       <li onClick={e=>navigateTo("/admin/order")}> <a ><i className="bi bi-circle" />الطلبات</a>
       </li>
     </ul>
   </li>



   <li>
     <a onClick={e=>{
     localStorage.clear()
    navigateTo("/")
     }} aria-expanded="true">
       <div className="parent-icon"><i class='bx bxs-exit'></i>
       </div>
       <div className="menu-title">تسجيل الخروج</div>
     </a>
   </li>

   {/*end navigation*/}
 </ul></aside>
}

