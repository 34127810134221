import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Category Edit Component
const uploadImage = async (file) => {
    const formdata = new FormData();
    formdata.append("image", file);
    const response = await fetch(baseurl + "image/upload", {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
    });
    const json = await response.json();
    return json.image; // Return the uploaded image URL
};

// Category Edit Component
const CategoryEdit = () => {
    const { id } = useParams();
    const [item, setItem] = useState({ id: '', name: '', image: '', statuse: '' });
    const [imageFile, setImageFile] = useState(null); // State to hold the image file

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/category/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]); // Get the file from input
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        let uploadedImage = item.image; // Default to existing image

        // If a new image is selected, upload it
        if (imageFile) {
            uploadedImage = await uploadImage(imageFile);
        }

        // Update the item with the uploaded image
        const updatedItem = { ...item, image: uploadedImage };
        
        await axios.put(baseurl + `api/category/${id}`, updatedItem);
        // Redirect or show success message
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل الفئة</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
               
                    <div className="col-6">
                        <label className="form-label">الاسم</label>
                        <input type="text" name="name" value={item.name} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الصورة</label>
                        <input type="file" name="image" onChange={handleImageChange} className="form-control" />
                    </div>
              
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CategoryEdit;
