import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Order Detail Component
const OrderDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/order/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل الطلب</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">معرف الطلب</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">السعر</label>
                            <input type="text" value={item.price} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">الحالة</label>
                            <input type="text" value={item.status} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">معرف المستخدم</label>
                            <input type="text" value={item.user_id} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">معرف المؤسسة</label>
                            <input type="text" value={item.org_id} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">تاريخ</label>
                            <input type="text" value={item.date} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">وقت التسليم</label>
                            <input type="text" value={item.delivery_time} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">خط العرض</label>
                            <input type="text" value={item.user_lat} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">خط الطول</label>
                            <input type="text" value={item.user_lng} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">خط عرض المزود</label>
                            <input type="text" value={item.provider_lat} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">خط طول المزود</label>
                            <input type="text" value={item.provider_lng} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">العنوان</label>
                            <input type="text" value={item.address} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">رسوم التوصيل</label>
                            <input type="text" value={item.delivery_fee} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">طريقة الدفع</label>
                            <input type="text" value={item.payment} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">وصف العنوان</label>
                            <input type="text" value={item.descAddress} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">الرمز</label>
                            <input type="text" value={item.code} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">المستخدم</label>
                            <input type="text" value={item.user} disabled className="form-control" />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">العربات</label>
                            <input type="text" value={item.carts} disabled className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;