import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// مكون تفاصيل فئة القائمة
const MenuCategoryDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/menucategory/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل فئة القائمة</h3>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">معرف</label>
                        <input type="text" value={item.id} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">الاسم</label>
                        <input type="text" value={item.name} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">الصورة</label>
                        <input type="text" value={item.image} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">الحالة</label>
                        <input type="text" value={item.status} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">الأب</label>
                        <input type="text" value={item.parent} disabled className="form-control" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuCategoryDetail;