import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseurl, font } from '../../constants';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Replace this with your actual Base64 string
const arabicFontBase64 = font;

const Transactions = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${baseurl}api/transaction?page=${pageNumber}`);
            setData(response.data.items);
            setTotalPages(Math.ceil(response.data.total / 10));
        };
        fetchData();
    }, [pageNumber]);

    const exportToPDF = () => {
        const pdf = new jsPDF('l', 'pt', 'a4');

        // Register the Arabic font
        pdf.addFileToVFS('arabic.ttf', arabicFontBase64);
        pdf.addFont('arabic.ttf', 'ArabicFont', 'normal');
        pdf.setFont('ArabicFont', 'normal'); // Use the registered font
        pdf.setFontSize(12);

        // Add title
        pdf.text('قائمة المعاملات المالية', 40, 30);

        // Define the columns and data
        const columns = [
            { title: 'م', dataKey: 'index' },
            { title: 'رقم المعاملة', dataKey: 'id' },
            { title: 'التاريخ', dataKey: 'date' },
            { title: 'الغرض', dataKey: 'subject' },
            { title: 'الإجمالي', dataKey: 'total' },
            { title: 'طريقة الدفع', dataKey: 'paymentMethod' }
        ];
        
        const rows = data.map((item, index) => ({
            index: index + 1,
            id: item.id,
            date: item.date,
            subject: item.subject,
            total: item.total,
            paymentMethod: item.paymentMethod
        }));

        // Generate the table
        pdf.autoTable(columns, rows, { startY: 50 });

        // Save the PDF
        pdf.save('transactions.pdf');
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">قائمة المعاملات المالية</h3>
            <button className="btn btn-primary mb-3" onClick={exportToPDF}>
                Export as PDF
            </button>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">رقم المعاملة</th>
                                    <th scope="col">التاريخ</th>
                                    <th scope="col">الغرض</th>
                                    <th scope="col">الإجمالي</th>
                                    <th scope="col">طريقة الدفع</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.id}</td>
                                        <td>{item.date}</td>
                                        <td>{item.subject}</td>
                                        <td>{item.total}</td>
                                        <td>{item.paymentMethod}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* Pagination */}
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}>السابق</button>
                                </li>
                                {[...Array(totalPages)].map((_, index) => (
                                    <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => setPageNumber(index + 1)}>{index + 1}</button>
                                    </li>
                                ))}
                                <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}>التالي</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Transactions;