import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseurl, formateDate } from '../../constants';

// مكون قائمة المستخدمين
const UserList = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(baseurl + `api/user?page=${pageNumber}`);
            setData(response.data.items);
            setTotalPages(Math.ceil(response.data.total / 10));
        };
        fetchData();
    }, [pageNumber]);

    const handleDelete = async (id) => {
        if (window.confirm('هل أنت متأكد أنك تريد حذف هذا العنصر؟')) {
            await axios.delete(baseurl + `api/user/` + id);
            setData(data.filter(item => item.id !== id));
        }
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">قائمة المستخدمين</h3>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">م</th>
                                    <th scope="col">الاسم الكامل</th>
                                    <th scope="col">البريد الإلكتروني</th>
                                    <th scope="col">الهاتف</th>
                                    <th scope="col">الصورة</th>
                                    <th scope="col">تاريخ التسجيل</th>
                                    <th scope="col">تعديل</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.fullName}</td>
                                        <td>{item.email}</td>
                                        <td>{item.userName}</td>
                                        <td>
                                            <div className="d-flex align-items-center gap-3 cursor-pointer">
                                                <img src={baseurl + "/uploads/" + item.profileImage} className="rounded-circle" width={44} height={44} alt="" />
                                            </div>
                                        </td>
                                        <td>{formateDate(item.createdAt)}</td>

                                        <td>
                                            <Link to={"/admin/user/detail/" + item.id} className="btn btn-primary m-1">عرض</Link>
                                            <Link to={"/admin/user/edit/" + item.id} className="btn btn-success m-1">تعديل</Link>
                                            <button className="btn btn-danger m-1" onClick={() => handleDelete(item.id)}>حذف</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* التصفح */}
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}>السابق</button>
                                </li>
                                {[...Array(totalPages)].map((_, index) => (
                                    <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => setPageNumber(index + 1)}>{index + 1}</button>
                                    </li>
                                ))}
                                <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}>التالي</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UserList;