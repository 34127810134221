import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';
import '../ImageGrid/ImageGrid.css';

// Service Detail Component
const ServiceDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});
    const [images, setImages] = useState();
    const downloadImage = async (src) => {
        try {
            const response = await fetch(src);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `image-${Date.now()}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };


    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/service/` + id);
            console.log(response);

            setItem(response.data.item);
            setImages(response.data.images);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل الطلعة</h3>
            <div className="card">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-6 mb-3">
                            <label className="form-label">رقم الطلعة</label>
                            <input type="text" value={item.id} disabled className="form-control" />
                        </div>
                       
                        <div className="col-6 mb-3">
                            <label className="form-label">الاسم</label>
                            <input type="text" value={item.name} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الصورة</label>
                            <input type="text" value={item.image} disabled className="form-control" />
                        </div>
                       
                        <div className="col-6 mb-3">
                            <label className="form-label">الوصف</label>
                            <input type="text" value={item.desc} disabled className="form-control" />
                        </div>
                      
                        <div className="col-6 mb-3">
                            <label className="form-label">التكلفة / ساعة</label>
                            <input type="text" value={item.cost} disabled className="form-control" />
                        </div>
                   
                        <div className="col-6 mb-3">
                            <label className="form-label">الموقع</label>
                            <input type="text" value={item.location} disabled className="form-control" />
                        </div>
                      
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف الفئة</label>
                            <input type="text" value={item.categoryId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف المستخدم</label>
                            <input type="text" value={item.userId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">معرف الحزمة</label>
                            <input type="text" value={item.bundleId} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">تاريخ الإنشاء</label>
                            <input type="text" value={item.createdAt} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">أقصى عدد للحجوزات</label>
                            <input type="text" value={item.maxBookings} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المدفوعات</label>
                            <input type="text" value={item.payments} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الحد الأدنى للأشخاص</label>
                            <input type="text" value={item.minPersons} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">عدد الحجوزات</label>
                            <input type="text" value={item.countBookings} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">إجمالي الأشخاص</label>
                            <input type="text" value={item.totalPersons} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">المدينة</label>
                            <input type="text" value={item.city} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">السعر</label>
                            <input type="text" value={item.price} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">العنوان</label>
                            <input type="text" value={item.address} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">وصف العنوان</label>
                            <input type="text" value={item.descAddress} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الرابط</label>
                            <input type="text" value={item.lnk} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">بيانات تعريفية</label>
                            <input type="text" value={item.meta} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">وقت البدء</label>
                            <input type="text" value={item.startTime} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">هل تم الحجز؟</label>
                            <input type="text" value={item.isBooked} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الوصف بالإنجليزية</label>
                            <input type="text" value={item.desc_en} disabled className="form-control" />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">الاسم بالإنجليزية</label>
                            <input type="text" value={item.name_en} disabled className="form-control" />
                        </div>
                        <div className="col-12 row mb-3">
                        {images&&images.map((src, index) => (
                            <div className='img-service grid-item' key={index} >
                                <img  src={baseurl+"uploads/"+src.url} alt={`Image ${index}`} />
                                <button onClick={() => downloadImage(src.url)}>تحميل</button>
                            </div>
                        ))}

                        </div>

                      

                    </div>
                </div>
            </div>

        </div>
    );
};
export default ServiceDetail;