

import { useEffect, useState } from "react";

import { baseurl, uploadImage } from "../constants";
import useLoader from "../Components/loader/useLoader";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export default function Home() {
  const navigateTo = useNavigate()

  const [data, setData] = useState()
  const [loader, showLoader, hideLoader] = useLoader();
  const getData = async () => {
    showLoader()
    try {
      const response = await fetch(baseurl + "dashboard-home", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      console.log(json);
      hideLoader()
      setData(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('هل أنت متأكد أنك تريد حذف هذا العنصر؟')) {
      await axios.delete(baseurl + `api/service/${id}`);
      getData()
    }
  };

  useEffect(() => {
    getData()
  }, []);

  return <>
    {loader}
    {data && <>
      <div class="row row-cols-1 text-white row-cols-lg-2 row-cols-xl-4">
        <div class="col">
          <div onClick={e => navigateTo("/admin/providers")} class="card rounded-4 btn btn-primary">
            <div class="card-body ">
              <div class="d-flex align-items-center">
                <div class="">
                  <p class="mb-1">مزودي الخدمة</p>
                  <h4 class="mb-0">{data.providers}</h4>
                  <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد المزودين : {data.providers}</span></p>
                </div>
                <div class="ms-auto widget-icon bg-white text-primary">
                  <i class='bi bi-grid-fill'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div onClick={e => navigateTo("/admin/users")} class="card btn btn-secondary rounded-4">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="">
                  <p class="mb-1">المستخدمين</p>
                  <h4 class="mb-0">{data.users}</h4>
                  <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد المستخدمين : {data.users}</span>
                  </p>
                </div>
                <div class="ms-auto widget-icon bg-white text-secondary">
                  <i class='bi bi-person-fill'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div onClick={e => navigateTo("/admin/service")} class="card btn btn-danger rounded-4">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="">
                  <p class="mb-1">الطلعات</p>
                  <h4 class="mb-0">{data.services}</h4>
                  <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد الطلعات : {data.services} </span>
                  </p>
                </div>
                <div class="ms-auto widget-icon bg-white text-orange">
                  <i class='bi bi-award-fill'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card btn btn-success rounded-4">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="">
                  <p class="mb-1">الأرباح المحققة  </p>
                  <h4 class="mb-0">{data.provenCommession} ريال</h4>
                  <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>الأرباح غير المحققة  : {data.unProvenCommession} ريال</span>
                  </p>
                </div>
                <div class="ms-auto widget-icon bg-white text-success">
                  <i class="bx bxs-package"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap5">
              <div className="row">
                <div className="col-sm-12">



                  <table id="DataTable" className="table table-striped table-bordered dataTable" role="grid" aria-describedby="example2_info">
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">م</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">الاسم</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">المدينة</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">سعر الحجز</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">تاريخ الاضافة</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">صورة</span></th>
                        <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{ width: '158.828px' }}><span className="mx-3">تعديل</span></th>

                      </tr>
                    </thead>
                    <tbody>

                      {data && data.lastServices.map((item, index) => <tr role="row" className="odd">
                        <td><span className="mx-3">{index + 1}</span></td>
                        <td>{item.name}</td>
                        <td>{item.city}</td>

                        <td>{item.price}</td>
                        <td>{item.createdAt}</td>
                        <td> <div className="d-flex align-items-center gap-3 cursor-pointer">
                          <img src={baseurl + "/uploads/" + item.image} className="rounded-circle" width={44} height={44} alt="" />
                        </div></td>
                        <td>
                                            <Link to={"/admin/service/detail/" + item.id} className="btn btn-primary m-1">عرض</Link>
                                            <Link to={"/admin/service/edit/" + item.id} className="btn btn-success m-1">تعديل</Link>
                                            <button className="btn btn-danger m-1" onClick={() => handleDelete(item.id)}>حذف</button>
                                        </td>
                      </tr>)}

                    </tbody>
                  </table>
                  <br />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>}
  </>


}