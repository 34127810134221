import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

// Category Detail Component
const CategoryDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/category/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تفاصيل الفئة</h3>
            <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">رقم الفئة</label>
                        <input type="text" value={item.id} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">الاسم</label>
                        <input type="text" value={item.name} disabled className="form-control" />
                    </div>
                    <div className="mb-3">
                        <img 
                                                    src={baseurl+"uploads/"+item.image} 
                                                    alt={item.name} 
                                                    style={{ 
                                                        borderRadius: '50%', 
                                                        width: '150px', 
                                                        height: '150px', 
                                                        objectFit: 'cover' 
                                                    }} 
                                                />                    </div>
                  
                </div>
            </div>
        </div>
    );
};

export default CategoryDetail;