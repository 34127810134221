import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { baseurl } from '../../constants';

// مكون تعديل فئة القائمة
const MenuCategoryEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({ id: '', name: '', image: '', status: '', parent: '' });

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/menucategory/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(baseurl + `api/menucategory/` + item.id, item);
        // إعادة التوجيه أو عرض رسالة النجاح
        navigate(`/admin/menucategory`);
    };

    return (
        <div className="container">
            <h3 className="mt-4 mb-4">تعديل فئة القائمة</h3>
            <form onSubmit={handleSubmit}>
                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">معرف</label>
                        <input type="text" name="id" value={item.id} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الاسم</label>
                        <input type="text" name="name" value={item.name} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الصورة</label>
                        <input type="text" name="image" value={item.image} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الحالة</label>
                        <input type="text" name="status" value={item.status} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-6">
                        <label className="form-label">الأب</label>
                        <input type="text" name="parent" value={item.parent} onChange={handleChanges} className="form-control" />
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary">تحديث</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MenuCategoryEdit;